import {DependencyList, EffectCallback, useEffect} from 'react'
import useDeepCompareMemoize from '../deep-compare-memoize'
import { isPrimitive } from '../../utils'

export function assertDependencies(dependencies: DependencyList): void {
  if (!dependencies || !dependencies.length) {
    throw new Error(
      'useDeepCompareEffect should not be used with no dependencies. Use React.useEffect instead.',
    )
  }

  if (dependencies.every(isPrimitive)) {
    throw new Error(
      'useDeepCompareEffect should not be used with dependencies that are all primitive values. Use React.useEffect instead.',
    )
  }
}

function useDeepCompareEffect(effect: EffectCallback, deps: DependencyList): void {
  if (process.env.NODE_ENV !== 'production') {
    assertDependencies(deps)
  }

  useEffect(effect, useDeepCompareMemoize(deps))
}

export function useDeepCompareEffectNoCheck(effect: EffectCallback, deps: DependencyList): void {
  useEffect(effect, useDeepCompareMemoize(deps))
}

export default useDeepCompareEffect
