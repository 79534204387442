import React from 'react';
import {render} from 'react-dom';
import {ThemeProvider} from 'theme-ui';
import Application from './components/application';
// import * as serviceWorker from './serviceWorker';
import theme from './theme';

render(
  <ThemeProvider theme={theme}>
    <Application />
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
