import {useState} from 'react';
import ky from 'ky';
import {parseISO} from 'date-fns';
import debug from 'debug';
import useDeepCompareEffect from '../deep-compare-effect';

const d = debug('useHistory');

type Unit = 'WATT_HOUR' | 'DEGREE_CELSIUS' | 'CUBIC_METRE_PER_HOUR';

type RawMeasurement = {
  quantity: number
  units: Unit
}

type RawData = {
  accessCount: number
  deviceId: number
  energy: RawMeasurement
  flow: {temperature: RawMeasurement, temperatureDifference: RawMeasurement, volume: RawMeasurement}
  timestamp: string
}

async function fetchDataForDate (date: Date): Promise<RawData[]> {
  d('Fetch measurements for date %s', date.toISOString());

  let response = await ky.get('history', {searchParams: {startDate: date.toISOString()}});
  d('Retrieved %s [%d] response from API', response.statusText, response.status)

  let parsed = await response.json()
  d('Found %d raw measurements in response', parsed.length);

  return parsed
}

export type Measurement = {
  date: Date
  energy: number
  temperature: number
  volume: number
}

function mapDataToMeasurements (data: RawData[]): Measurement[] {
  return data.map(data => ({
    date: parseISO(data.timestamp),
    energy: data.energy.quantity,
    temperature: data.flow.temperatureDifference.quantity,
    volume: data.flow.volume.quantity,
  }));
}

export default function useMeasurements(date: Date): [Measurement[], boolean] {
  const [measurements, setMeasurements] = useState<Measurement[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  async function updateHistoryForDate(date: Date) {
    setLoading(true);

    let raw = await fetchDataForDate(date);

    let measurements = mapDataToMeasurements(raw);
    d('Mapped %d measurements from response', measurements.length);

    setMeasurements(measurements);
    setLoading(false);
  }

  useDeepCompareEffect(() => {
    updateHistoryForDate(date);
  }, [date])

  return [measurements, loading];
}
