/** @jsx jsx */
import React, {Fragment, useState} from 'react';
import {subHours} from 'date-fns';
import {Box, Flex, Image, jsx} from 'theme-ui';
import useMeasurements from '../../hooks/measurements';
import useInterval from '../../hooks/interval';
import EnergyChart from '../energy-chart';
import Loading from '../loading';
import VolumeChart from '../volume-chart';

interface ApplicationProps {
  interval?: number
}

const Application: React.FC<ApplicationProps> = ({ interval = 5 }) => {
  const [toDate, setToDate] = useState<Date>(new Date());
  const fromDate = subHours(toDate, 32)

  const [measurements, loading] = useMeasurements(fromDate);

  useInterval(() => {
    setToDate(new Date());
  }, interval * 60000);

  return (
    <Flex p={4} sx={{flexDirection: 'column', height: '100vh', overflow: 'hidden'}}>
      <Box sx={{textAlign: 'center'}}>
        <Image src="/images/logo-ennatuurlijk.png" sx={{height: '120px'}} />
      </Box>

      {(() => {
        if (loading && measurements.length === 0) {
          return <Loading />
        }

        return (
          <Fragment>
            <EnergyChart measurements={measurements} sx={{ flex: '1' }} />
            <VolumeChart measurements={measurements} sx={{ flex: '1' }} />
          </Fragment>
        )
      })()}

      <Box pt={4} sx={{textAlign: 'right'}}>
      <Image src="/images/logo-aurum.png" sx={{height: '30px'}} />
      </Box>
    </Flex>
  );
}

export default Application
