import {useRef} from 'react'
import deepEqual from 'dequal'

export default function useDeepCompareMemoize<T>(value: T): T | undefined {
  const ref = useRef<T>()

  if (!deepEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}
