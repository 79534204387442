import {useEffect, useRef} from 'react';

export default function useInterval<T extends (...args: never[]) => unknown>(callback: T, delay: number): void {
  const callbackRef = useRef<T>();

  function tick() {
    if (callbackRef.current) {
      callbackRef.current();
    }
  }

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}
