import React, {useState} from 'react';
import debug from 'debug';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useDeepCompareEffect from '../../hooks/deep-compare-effect';
import {Measurement} from '../../hooks/measurements';

const d = debug('EnergyChart');

function mapMeasurementsToSeries (measurements: Measurement[]) {
  return [{
    name: 'Temperature Δ (K)',
    type: 'spline',
    data: measurements.slice(1).map(measurement => ([measurement.date.valueOf(), measurement.temperature])),
    dashStyle: 'shortdot',
    marker: {
      enabled: false,
    },
    tooltip: {
      valueDecimals: 2,
      valueSuffix: ' K'
    }
  }, {
    name: 'Usage (J)',
    type: 'column',
    yAxis: 1,
    data: measurements.slice(1).map(
      (measurement, index) => ([measurement.date.valueOf(), Math.abs(measurements[index].energy - measurement.energy)])
    ),
  }]
}

interface EnergyChartProps {
  measurements: Measurement[]
}

const EnergyChart: React.FC<EnergyChartProps> = ({ measurements, ...props }) => {
  const [chartOptions, setChartOptions] = useState({
    xAxis: {
      type: 'datetime',
      tickInterval: 600000
    },
    yAxis: [{
      title: {
        text: 'Temperature Δ (K)'
      },
      opposite: true
    }, {
      gridLineWidth: 0,
      title: {
        text: 'Usage (J)'
      },
    }],
    series: [],
    time: {
      useUTC: false
    },
    title: null,
    tooltip: {
      shared: true,
      xDateFormat: '%d-%m-%Y %H:%M'
    },
    credits: {
      enabled: false
    },
  });

  useDeepCompareEffect(() => {
    d('Updating energy chart series with measurements');

    setChartOptions({
      series: mapMeasurementsToSeries(measurements)
    } as any);
  }, [measurements]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={props} />
}

export default EnergyChart;
