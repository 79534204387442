import React, {useState} from 'react';
import debug from 'debug';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import useDeepCompareEffect from '../../hooks/deep-compare-effect';
import {Measurement} from '../../hooks/measurements';

const d = debug('EnergyChart');

function mapMeasurementsToSeries (measurements: Measurement[]) {
  return [{
    name: 'Flow (m³/h)',
    data: measurements.map(measurement => ([measurement.date.valueOf(), measurement.volume])),
  }]
}

interface VolumeChartProps {
  measurements: Measurement[]
}

const VolumeChart: React.FC<VolumeChartProps> = ({ measurements, ...props }) => {
  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: 'area',
    },
    xAxis: {
      type: 'datetime',
      tickInterval: 600000
    },
    yAxis: {
      labels: {
        format: '{value:.2f}'
      },
      title: {
        text: 'Flow (m³/h)'
      }
    },
    series: [],
    time: {
      useUTC: false
    },
    title: null,
    tooltip: {
      shared: true,
      xDateFormat: '%d-%m-%Y %H:%M'
    },
    credits: {
      enabled: false
    },
  });

  useDeepCompareEffect(() => {
    d('Updating volume chart series with measurements');

    setChartOptions({
      series: mapMeasurementsToSeries(measurements)
    } as any);
  }, [measurements]);

  return (
    <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={props} />
  )
}

export default VolumeChart;
